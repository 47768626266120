
/**
 * Custom variable settings
 * Not available in Foundation
 * @path: settings/custom
 *
 * 1. Font
 * 2. Colors
 * 3. Global
 * 4. Flex
 * 5. Headings
 * 6. Spacing
 * 7. Breakpoints
 * 8. Grid
 * 9. Buttons
 * 10. Hero
 * 11. Card
 * 12. Navigation
 * 13. Footer
 * 14. Image
 * 15. Accordion
 * 16. Content block
 * 17. Datepicker
 * 18. Loader
 * 19. Icons
 * 20. Alert
 */

// 1. Font
// -----------
@font-face{
    font-family:'9292SansVF';
    src:url('/fonts/9292-sans/9292SansVF.woff2') format('woff2');
    font-weight:normal;
    font-style:normal;
    font-display: swap;
}

// 2. Colors
// -----------
$primary:               #0095db;
$secondary:             #3c0099;
$tertiary:              #1D4ED8;
$success:               #46973a;
$warning:               #f39c12;
$alert:                 #dd4b39;
$info:                  #2199e8;
$light-gray:            #f3f3f6;
$medium-gray:           #dedede;
$dark-gray:             #999999;
$black:                 #000000;
$white:                 #ffffff;
$light-primary:         darken($primary, 10%);
$light-secondary:       darken($secondary, 10%);
$light-black:           lighten($black, 40%);
$theme-colors: (
    'primary':      $primary,
    'secondary':    $secondary,
    'tertiary':     $tertiary,
    'success':      $success,
    'warning':      $warning,
    'alert':        $alert,
    'info':         $info,
    'light-gray':   $light-gray,
    'dark-gray':    $dark-gray,
    'black':        $black,
    'white':        $white
);

// 3. Global
// -----------
$body-background:           $white;
$body-font-size:            1.6rem;
$body-font-color:           #000000;
$body-font-family:          '9292SansVF', Arial, Helvetica, sans-serif;
$anchor-color:              $tertiary;
$anchor-color-hover:        darken($anchor-color, 10%);
$global-line-height:        1.5;
$global-weight-regular:     400;
$global-weight-semi-bold:   600;
$global-weight-bold:        700;
$global-radius:             1.2rem;
$global-spacing:            1.6rem;
$global-width:              none;

// 4. Flex
// ------------
$flex-x-alignment: (
    'left':     flex-start,
    'right':    flex-end,
    'center':   center,
    'justify':  space-between,
    'spaced':   space-around,
);

$flex-y-alignment: (
    'top':      flex-start,
    'bottom':   flex-end,
    'middle':   center,
    'stretch':  stretch,
);

// 5. Headings
// -----------
$headings: (
    xs: (
        'c-heading--xsmall'     : ('font-size': '1.6rem', 'font-weight': 600),
        'c-heading--small'      : ('font-size': '1.8rem', 'font-weight': 600),
        'c-heading--medium'     : ('font-size': '2.2rem', 'font-weight': 600),
        'c-heading--large'      : ('font-size': '2.8rem', 'font-weight': 600),
        'c-heading--xlarge'     : ('font-size': '3.6rem', 'font-weight': 600),
        ),
    md: (
        'c-heading--xsmall'     : ('font-size': '1.8rem'),
        'c-heading--small'      : ('font-size': '2rem'),
        'c-heading--medium'     : ('font-size': '2.6rem'),
        'c-heading--large'      : ('font-size': '3.6rem'),
        'c-heading--xlarge'     : ('font-size': '4.6rem'),
        ),
    lg: (
        'c-heading--xsmall'     : ('font-size': '2rem'),
        'c-heading--small'      : ('font-size': '2.4rem'),
        'c-heading--medium'     : ('font-size': '3rem'),
        'c-heading--large'      : ('font-size': '4.4rem'),
        'c-heading--xlarge'     : ('font-size': '5.6rem'),
        )
);
$header-font-family: '9292SansVF';
$header-font-weight: $global-weight-bold;
$header-line-height: 1.2;

// 6. Spacing
// -----------
$v-spacing: (
    xs: (
        'xsmall'    : '.8rem',
        'small'     : '1.6rem',
        'medium'    : '3.2rem',
        'large'     : '4rem',
        'xlarge'    : '12rem'
    ),
    md: (
        'small'     : '2rem',
        'medium'    : '4rem',
        'large'     : '7.2rem',
    ),
    lg: (
        'small'     : '2.4rem',
        'medium'    : '4.8rem',
        'xlarge'    : '13.6rem'
    ),
    xl: (
        'xlarge'    : '16.8rem'
    )
);

// 7. Breakpoints
// -----------
$breakpoints: (
    xs:     0,
    sm:     576px,
    md:     768px,
    lg:     992px,
    xl:     1200px,
    xxl:    1400px
);
$zero-breakpoint: xs;
// All of the names in this list will be output as classes in your CSS, like `.small-12`,
// `.medium-6`, and so on. Each value in this list must also be in the `$breakpoints` map.
$breakpoint-classes: (xs sm md lg xl xxl);

// 8. Grid
// -----------
$grid-max-width:        129.2rem;
$grid-column-count:     12;
$grid-column-gutter: (
    xs:     2.4rem,
    md:     3.6rem,
    xl:     4.4rem
);

// 9. Buttons
// -----------
$button-font-family:                inherit;
$button-font-weight:                $global-weight-bold;
$button-padding:                    1.2rem 2.4rem;
$button-radius:                     .8rem;
$button-sizes: (
    xsmall:     1.2rem,
    small:      1.4rem,
    default:    $body-font-size,
    large:      1.8rem,
);
$button-opacity-disabled:                   0.25;
$button-transition:                         background-color 0.25s ease-out, color 0.25s ease-out, border-color 0.25s ease-out, background-position .25s ease-out;
$button-padding-icon:                       .6rem;
// Solid buttons
$button-background:                         $secondary;
$button-has-gradient:                       true;
$button-background-gradient:                linear-gradient(195deg, #2563eb 10%, $secondary);
$button-background-gradient-hover:          linear-gradient(195deg, #286bff 10%, #5000cc);
$button-background-hover:                   $light-secondary;
$button-color:                              $white;
$button-color-hover:                        $button-color;
$button-border:                             .1rem solid transparent;
$button-border-hover:                       transparent;
// Hollow buttons
$button-hollow-background:                  transparent;
$button-hollow-background-hover:            $button-hollow-background;
$button-hollow-color:                       $secondary;
$button-hollow-color-hover:                 $light-secondary;
$button-hollow-border:                      .1rem solid $button-hollow-color;
$button-hollow-border-hover:                .1rem solid $button-hollow-color-hover;
// Solid buttons secondary
$button-background-secondary:               $black;
$button-background-hover-secondary:         $light-black;
$button-color-secondary:                    $white;
$button-color-hover-secondary:              $button-color;
$button-border-secondary:                   .1rem solid transparent;
$button-border-hover-secondary:             transparent;
// Hollow buttons secondary
$button-hollow-background-secondary:        transparent;
$button-hollow-background-hover-secondary:  $button-hollow-background-secondary;
$button-hollow-color-secondary:             $black;
$button-hollow-color-hover-secondary:       $light-black;
$button-hollow-border-secondary:            .1rem solid $button-hollow-color-secondary;
$button-hollow-border-hover-secondary:      .1rem solid $button-hollow-color-hover-secondary;
// Hollow buttons white
$button-hollow-background-hover-white:      $white;
$button-hollow-color-white:                 $white;
$button-hollow-color-hover-white:           $primary;
$button-hollow-border-white:                .1rem solid $button-hollow-color-white;
$button-hollow-border-hover-white:          .1rem solid $button-hollow-color-white;
// Clear buttons
$button-clear-background:                   transparent;
$button-clear-background-hover:             transparent;
$button-clear-color:                        $tertiary;
$button-clear-color-hover:                  $anchor-color-hover;
$button-clear-border:                       .1rem solid transparent;
$button-clear-border-hover:                 .1rem solid transparent;

$button-disabled-background:                $dark-gray;
$button-disabled-color:                     $white;

// 10. Hero
// -----------
$hero-content-background: rgba($black, .6);
$hero-content-padding: (
    sm: 2.4rem,
    md: 4.8rem
);

// 11. Card
// -----------
$card-background:                           $white;
$card-padding:                              2.4rem 2.4rem 0;
$card-radius:                               .8rem;
$card-transition:                           box-shadow .5s cubic-bezier(0.165, 0.84, 0.44, 1);
$card-image-height:                         25rem;
$card-image-width:                          100%;
$card-image-fit:                            cover;
$card-image-transition:                     transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
$card-title-color:                          $black;
$card-title-color-hover:                    $primary;
$card-title-transition:                     color 0.25s ease-out;

// 12. Navigation
// -----------
$nav-background:                            $white;
$nav-height:                                8rem;
$nav-radius:                                $global-radius;
$nav-padding:                               0 2.4rem;
$nav-item-transition:                       color 0.25s ease-out;
$nav-item-hover:                            $tertiary;
$nav-shadow:                                0 .125rem .25rem rgba($black, .075);
$nav-drawer-item-font-size:                 2rem;

// 13. Footer
// -----------
$footer-background:                         $black;
$footer-color:                              $white;
$footer-padding:                            2rem 0;
$footer-font-size:                          1.4rem;

// 14. Image
// -----------
$image-border-radius:                       $global-radius;
$image-height: (
    sm: 35rem,
    md: 50rem
);

// 15. Accordion
// -----------
$accordion-padding:                         2rem;
$accordion-border:                          .1rem solid $black;

// 16. Content block
// -----------
$content-block-background:                  $white;
$content-block-color:                       $black;
$content-block-padding:                     2.4rem;
$content-block-border-radius:               $global-radius;
$content-block-inner-border-color:          .1rem solid $black;

// 17. Datepicker
// -----------
$datepicker-font-family:                    $body-font-family;
$datepicker-font-size:                      1.2rem;
$datepicker-menu-min-width:                 100%;

// 18. Loader
// -----------
$loader-block-background:                   $black;
$loader-block-size:                         2.4rem;
$loader-xsmall-block-size:                  .8rem;

// 19. Icons
// Font Awesome 5 Pro (5.0.1) icons
// @cheatsheet: https://fontawesome.com/icons?d=gallery&q=&s=brands,regular,solid
// -----------
$fa-font-name-light:                'fa-light-300';
$fa-font-name-regular:              'fa-regular-400';
$fa-font-name-brands:               'fa-brands-400';
$fa-font-name-solid:                'fa-solid-900';
$fa-icons: (
    'arrow-left':                   '\f060',
    'arrow-right':                  '\f061',
    'arrow-up-right-from-square':   '\f08e',
    'bin':                          '\f2ed',
    'check':                        '\f00c',
    'chevron-down':                 '\f078',
    'chevron-right':                '\f054',
    'chevron-up':                   '\f077',
    'location':                     '\f3c5',
    'preview':                      '\f865',
    'times':                        '\f00d',
    'user':                         '\f406',
    'triangle-exclamation':         '\f071'
);

// 20. Alert
// -----------
$alert-padding-top:                 1.6rem;
$alert-padding-right:               $alert-padding-top;
$alert-padding-bottom:              $alert-padding-top;
$alert-padding-left:                $alert-padding-top;
$alert-font-weight:                 normal;
$alert-font-size:                   1.6rem;
$alert-border-style:                solid;
$alert-border-width:                1px;
$alert-bottom-margin:               2.4rem;
$alert-radius:                      .5rem;
$alert-transition-speed:            300ms;
$alert-transition-ease:             ease-out;

// Sticky
$alert-sticky-max-width:            $grid-max-width;
$alert-sticky-min-height:           $nav-height;
$alert-sticky-margin:               1.6rem 0 0 0;

// Close button
$alert-close-font-size:             2.2rem;
$alert-close-padding:               .8rem;