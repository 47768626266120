/**
 * Hero
 * @path: components/hero
 */

.c-hero {
    &__slide {
        &--large {
            height: 70vh;
            min-height: 58rem;
        }
        &--medium {
            height: 50vh;
        }
    }

    &__cards {
        margin-top: 3.2rem;
        position: relative;
        z-index: 5;
    }
}

.c-hero--featured {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .c-hero__swirl--small {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
    }

    .c-hero__swirl--large {
        position: absolute;
        z-index: -1;
        right: -14.7rem;
        bottom: 0;
    }

    .c-hero__image {
        margin-top: 3.2rem;
    }
}

.c-hero {
    .c-swiper-slider {
        margin-top: 3.2rem;
    }
}

@include breakpoint(md) {
    .c-hero {
        &__cards {
            margin-top: -10rem;
        }
    }

    .c-hero--featured {
        padding-top: 4.8rem;
        padding-bottom: 15.6rem;

        &.c-hero--slider{
            padding-bottom: 4.8rem;
        }

        .c-hero__swirl--large {
            right: 0;
        }
    }

    .c-hero {
        .c-swiper-slider {
            margin-top: 4.8rem;
        }
    }
}

@include breakpoint(lg) {
    .c-hero--featured {
        overflow: visible;

        .c-hero__content {
            padding-right: 4.8rem;
        }

        .c-hero__title {
            font-size: 4rem;
            font-weight: 600;
        }

        .c-hero__swirl--large {
            right: auto;
            left: 29.667%;
            bottom: 0;
        }
    }
}

@include breakpoint(xl) {
    .c-hero--featured {
        padding-top: 11.2rem;

        &.c-hero--slider{
            padding-bottom: 4.8rem;
        }

        .c-hero__image {
            margin-top: -16.8rem;
        }
    }
}
